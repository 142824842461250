import { VFC } from 'react'
import Link from 'gatsby-link'
import { useLocation } from '@reach/router'
import styled, { css } from 'styled-components'
import { LinkButtonProps, YougoLinkListProps } from '@/components/types'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig, mixins } from '@/utils/styleConfig'

// OPTIMIZE: とりあえずおいておく
interface LinkTabProps {
  active: boolean
}

export const YougoLinkList: VFC<YougoLinkListProps> = ({ linkList }) => {
  const location = useLocation()

  const isActive = link => {
    return link === location.pathname
  }

  return (
    <StyledYougoLinkList>
      {linkList.map(item => (
        <li key={item.title}>
          <Link to={item.link}>
            <LinkTab active={isActive(item.link)}>{item.title}</LinkTab>
          </Link>
        </li>
      ))}
    </StyledYougoLinkList>
  )
}

const StyledYougoLinkList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem 0.8rem;

  ${breakpointUp('md')} {
    grid-template-columns: repeat(6, 1fr);
  }
`

const LinkTab = styled.span<LinkTabProps>`
  ${mixins.hoverButton()};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 9999px;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: 0.08em;
  padding: 0.8rem 2.4rem;
  width: 100%;
  font-size: 1.4rem;
  background-color: ${colorConfig.themeColor};
  color: ${colorConfig.textSubColor};

  ${({ active }) =>
    active
      ? css`
          border: 0.2rem solid ${colorConfig.themeColor};
          color: ${colorConfig.themeColor};
          background-color: ${colorConfig.textSubColor};

          &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: -1rem;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.8rem 0.8rem 0 0.8rem;
            border-color: ${colorConfig.themeColor} transparent transparent transparent;

            ${breakpointUp('md')} {
              bottom: -0.8rem;
            }
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -0.8rem;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.8rem 0.8rem 0 0.8rem;
            border-color: ${colorConfig.textSubColor} transparent transparent transparent;

            ${breakpointUp('md')} {
              bottom: -0.6rem;
            }
          }
        `
      : css`
          border: 0.2rem solid ${colorConfig.themeColor};
          color: ${colorConfig.textSubColor};
          background-color: ${colorConfig.themeColor};
        `}
`
