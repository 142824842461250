import { VFC } from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import { YougoListProps } from '@/components/types'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig, mixins } from '@/utils/styleConfig'

export const YougoList: VFC<YougoListProps> = ({ titleList, link }) => (
  <StyledYougoList>
    {titleList.length ? (
      titleList.map(yougo => (
        <li key={yougo.id}>
          <Link to={`${link}detail/#${yougo.id}`}>
            <YougoLink>{yougo.title}</YougoLink>
          </Link>
        </li>
      ))
    ) : (
      <li>
        <NotApplicable>該当なし</NotApplicable>
      </li>
    )}
  </StyledYougoList>
)

const StyledYougoList = styled.ul`
  ${breakpointUp('md')} {
    column-count: 2;
    gap: 4rem;

    > li {
      break-inside: avoid;
    }
  }
`

const YougoLink = styled.span`
  ${mixins.hoverDefault(colorConfig.themeColor)};
  display: block;
  padding: 0.8rem;
  border-bottom: 1px dotted ${colorConfig.borderColor};
  width: 100%;
  color: ${colorConfig.themeColor};
  font-size: 1.4rem;
`

const NotApplicable = styled.span`
  display: block;
  padding: 0.8rem;
  border-bottom: 1px dotted ${colorConfig.borderColor};
  width: 100%;
  font-size: 1.4rem;
`
